* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
}

body {
  min-height: 100vh;
  padding-top: 2.8rem;
  background-color: rgba(157, 4, 4, 1);
  background-image: radial-gradient(circle at 50% 50%, rgba(248, 222, 6, 1), rgba(157, 4, 4, 1));
  background-attachment: fixed;
  color: white;
}

* {
  font-family: 'Source Sans Pro', sans-serif;
}

h1, h2, h3 {
  font-family: 'Permanent Marker', cursive;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes modal-enter {
  0% {
    padding-top: 100vh;
    background-color: rgba(157, 4, 4, 0);
  }
  20% {
    background-color: rgba(157, 4, 4, 1);
  }
  100% {
    padding-top: 0;
  }
}

@keyframes modal-exit {
  0% {
    padding-top: 0;
    background-color: rgba(157, 4, 4, 1);
  }
  80% {
    background-color: rgba(157, 4, 4, 0);
  }
  100% {
    padding-top: 100vh;
  }
}

@media only screen and (min-width: 750px) {
  body {
    padding-top: 6rem;
  }
}