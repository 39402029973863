.UserMessages {
  position: fixed;
  top: 0;
  left: 50%;
  margin-left: -150px;
  width: 300px;
  background: transparent;
  z-index: 25;
}

.MessageRow {
  padding: .3em .8em;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  border-radius: 0 0 8px 8px;
  box-shadow: 1px 2px 0 rgba(0,0,0,.5);
  font-size: .9rem;
  height: 3em;
  overflow: hidden;
  transition: all .2s linear;
  animation: open-down .2s linear;
}

.messageRow--message {
  background-color: green;
  background: linear-gradient(0.15turn, green, rgb(0, 70, 0));
}

.messageRow--error {
  background-color: red; 
  background: linear-gradient(0.15turn, rgb(255, 0, 0), rgb(170, 40, 1));
}

.messageRow__dismiss {
  margin-left: 10px;
  padding-left: 10px;
  text-align: right;
  display: flex;
  justify-content: center;
  align-items: center;
  text-shadow: 1px 2px 0 rgba(0,0,0,.5);
  border-left: 1px solid rgba(255, 255, 255, .4);
}

.messageRow--dismissing {
  height: 0;
  padding: 0;
}

@keyframes open-down {
  from {
    height: 0;
  }
  to {
    height: 3em;
  }
}

@media only screen and (min-width: 750px) {
  .messageRow__dismiss {
    transition: all .3s;
    cursor: pointer;
  }
  .messageRow__dismiss:hover {
    text-shadow: 2px 4px 3px rgba(0,0,0,.5);
  }
}