.TaskDetail {
  animation: fade-in .3s linear;
  position: relative;
  padding-bottom: 3rem;
}

.taskDetail__segmented-wrapper {
  display: flex;
  justify-content: center;
}

.taskDetail__segmented {
  color: white;
  margin: 0;
}

.segmented-control {
  border-radius: 8px !important;
  max-width: 450px !important;
}

.segmented-control label::after {
  color: black !important;
}

.taskDetail__sub-heading {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1rem;
  font-weight: normal;
  margin: 0;
}

.taskDetail__content {
  margin-top: 0;
  background-color: rgba(0, 0, 0, .3);
  padding: 5px 10px;
  border-radius: 8px;
}

.taskDetail__description {
  margin-top: 0;
}

.taskDetail__title {
  margin-top: 0;
  text-align: center;
  font-weight: bold;
}

@media only screen and (min-width: 750px) {
  .taskDetail__sub-heading {
    text-align: center;
    font-size: 1.2rem;
  }
  .taskDetail__title {
    font-size: 1.5rem;
  }
  .taskDetail__content {
    padding: 20px 30px;
    border-radius: 10px;
  }

  .segmented-control label {
    text-shadow: 2px 4px 4px rgba(0,0,0,.5);
  }
}