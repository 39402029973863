* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
}

body {
  min-height: 100vh;
  padding-top: 2.8rem;
  background-color: rgba(157, 4, 4, 1);
  background-image: -webkit-radial-gradient(50% 50%, circle, rgba(248, 222, 6, 1), rgba(157, 4, 4, 1));
  background-image: radial-gradient(circle at 50% 50%, rgba(248, 222, 6, 1), rgba(157, 4, 4, 1));
  background-attachment: fixed;
  color: white;
}

* {
  font-family: 'Source Sans Pro', sans-serif;
}

h1, h2, h3 {
  font-family: 'Permanent Marker', cursive;
}

@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@-webkit-keyframes modal-enter {
  0% {
    padding-top: 100vh;
    background-color: rgba(157, 4, 4, 0);
  }
  20% {
    background-color: rgba(157, 4, 4, 1);
  }
  100% {
    padding-top: 0;
  }
}

@keyframes modal-enter {
  0% {
    padding-top: 100vh;
    background-color: rgba(157, 4, 4, 0);
  }
  20% {
    background-color: rgba(157, 4, 4, 1);
  }
  100% {
    padding-top: 0;
  }
}

@-webkit-keyframes modal-exit {
  0% {
    padding-top: 0;
    background-color: rgba(157, 4, 4, 1);
  }
  80% {
    background-color: rgba(157, 4, 4, 0);
  }
  100% {
    padding-top: 100vh;
  }
}

@keyframes modal-exit {
  0% {
    padding-top: 0;
    background-color: rgba(157, 4, 4, 1);
  }
  80% {
    background-color: rgba(157, 4, 4, 0);
  }
  100% {
    padding-top: 100vh;
  }
}

@media only screen and (min-width: 750px) {
  body {
    padding-top: 6rem;
  }
}
.loading-text {
  -webkit-animation: fade-in .3s linear;
          animation: fade-in .3s linear;
}
.Header-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 15;
  background-color: rgba(157, 4, 4, 1);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  box-shadow: 0 2px 4px 2px rgba(0,0,0,.5);
}

.Header {
  margin: 0 auto;
  width: 100%;
  max-width: 800px;
  padding: .5rem;
}

.header__top-line {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.header__logo {
  margin: 0;
  font-size: 1.5rem;
  letter-spacing: .1em;
  text-shadow: 2px 4px 2px rgba(0,0,0,.5);
}

.header__nav {
  height: 0;
  overflow: hidden;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  text-align: center;
}

.header__nav--visible {
  height: 11.5rem;
}

.header__nav-list {
  list-style: none;
  padding: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
          flex-flow: column nowrap;
  text-align: center;
  margin: .5rem 0 0;
}

.header__nav-item {
  display: inline-block;
  padding: .8rem;
  line-height: 1em;
  font-size: 1.2rem;
  border-bottom: 1px solid rgba(255, 255, 255, .4);
}

.header__nav-item a {
  color: white;
  text-decoration: none;
}

@media only screen and (min-width: 750px) {
  .Header-wrapper {
    box-shadow: 0 2px 10px 4px rgba(0,0,0,.5);
  }
  .Header {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    padding: 1.5rem 10px;
    max-width: 900px;
  }

  .header__logo {
    font-size: 2rem;
  }
  
  .MenuButton {
    display: none;
  }
  .header__nav {
    height: auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: center;
            align-items: center;
    text-align: right;
  }
  .header__nav-list {
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    margin: 0;
  }
  .header__nav-item {
    border-bottom: 2px solid transparent;
    padding: .4em 0;
    margin: 0 0 0 1.2em;
    -webkit-transition: all .3s;
    transition: all .3s;
    cursor: pointer;
  }
  .header__nav-item, .header__nav-item a {
    font-family: 'Permanent Marker', cursive;
    text-shadow: 2px 4px 2px rgba(0, 0, 0, .5);
  }
  .header__nav-item:hover {
    border-bottom: 2px solid rgba(255,255,255, .5);
  }
}
.MenuButton {
  height: 24px;
  width: 30px;
  position: relative;
  padding: 0;
}

.mb_line {
  position: absolute;
  height: 4px;
  width: 30px;
  background-color: white;
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out;
  border-radius: 2px;
  box-shadow: 1px 2px 4px rgba(0,0,0,.4);
}

.mb_line_1 {
  top: 0;
  left: 0;
}

.mb_line_2 {
  top: 10px;
  right: 0;
}

.mb_line_3 {
  top: 20px;
}

.mb_open .mb_line_1 {
  left: 4px;
  -webkit-transform-origin: 0 50%;
          transform-origin: 0 50%;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.mb_open .mb_line_3 {
  top: 0;
  right: 5px;
  -webkit-transform-origin: 100% 50%;
          transform-origin: 100% 50%;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.mb_open .mb_line_2 {
  top: 26px;
}
.Main {
  padding: 10px;
  -webkit-animation: fade-in .3s linear;
          animation: fade-in .3s linear;
  max-width: 800px;
  margin: 0 auto;
}

.main__screen-name {
  margin: 0;
  text-shadow: 2px 4px 0 rgba(0,0,0,.5);
  text-align: center;
  font-size: 1.7rem;
  letter-spacing: .1em;
}

.main__page-title {
  margin-top: 0;
  font-size: 1.3rem;
  text-align: center;
  text-shadow: 2px 4px 0 rgba(0,0,0,.5);
  letter-spacing: .1em;
  padding: 0 15px;
}

@media only screen and (min-width: 750px) {
  .Main {
    max-width: 900px;
  }
  .main__page-title {
    font-size: 1.5rem;
    text-shadow: 2px 4px 2px rgba(0,0,0,.5);
  }
}
.Today {
  -webkit-animation: fade-in .3s linear;
          animation: fade-in .3s linear;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
}
.TaskList {
  width: 100%;
  margin-bottom: 15px;
}

.taskList__wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-animation: fade-in .3s linear;
          animation: fade-in .3s linear;
}

@media only screen and (min-width: 750px) {
  .taskList__wrapper {
    -webkit-justify-content: center;
            justify-content: center;
  }
}
.DailyTask {
  margin: 10px;
  text-align: center;
  width: 6rem;
  height: 6rem;
  border-radius: 8px;
  box-shadow: 0 0 50px rgba(0, 0, 0, .2);
  -webkit-backdrop-filter: blur(50px);
          backdrop-filter: blur(50px);
  padding: 5px;
  -webkit-animation: fade-in .3s linear;
          animation: fade-in .3s linear;
}

.dailyTask__title {
  margin: 0;
  font-size: 1rem;
  line-height: 1em;
  font-family: 'Source Sans Pro', sans-serif;
  text-shadow: 1px 2px 0 rgba(0,0,0,.5);
}

.dailyTask__complete {
  font-size: 3rem;
  margin: 0;
}

.dailyTask__complete a {
  text-decoration: none;
}

@media only screen and (min-width: 750px) {
  .DailyTask {
    width: 7rem;
    height: 7rem;
    -webkit-transition: all .3s;
    transition: all .3s;
  }
  .DailyTask:hover {
    -webkit-transform: scale(1.05, 1.05);
            transform: scale(1.05, 1.05);
    box-shadow: 0 0 50px 4px rgba(0, 0, 0, .2);
  }
  .dailyTask__complete {
    -webkit-transition: all .3s;
    transition: all .3s;
  }
  .dailyTask__complete:hover {
    -webkit-transform: scale(1.1, 1.1);
            transform: scale(1.1, 1.1);
  }
  .dailyTask__title {
    font-size: 1.2rem;
  }
}
.ChallengesList {
  width: 100%;
  margin-bottom: 15px;
}
.challengesList__wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-animation: fade-in .3s linear;
          animation: fade-in .3s linear;
}

@media only screen and (min-width: 750px) {
  .challengesList__wrapper {
    -webkit-justify-content: center;
            justify-content: center;
  }
}
.Progress {
  -webkit-animation: fade-in .3s linear;
          animation: fade-in .3s linear;
}

.ProgressBar {
  margin-bottom: 10px;
}

.progressBar__heading {
  margin: 0 auto 5px;
  width: 90%;
  max-width: 500px;
  text-shadow: 1px 2px 0 rgba(0,0,0,.5);
}

.ProgressMeter {
  margin-bottom: 10px;
}

.progressBar__holder {
  width: 90%;
  max-width: 500px;
  height: 30px;
  border: 3px ridge dimgray;
  border-radius: 8px;
  overflow: hidden;
  background-color: rgb(50,50,50);
  position: relative;
  box-shadow: 2px 2px 6px rgba(0,0,0,0.5);
  margin: 0 auto;
}

.ProgressMeter {
  margin-bottom: 10px;
}

.progressBar__meter-title {
  font-size: 1rem;
  padding: 0 8px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  font-weight: bold;
  text-shadow: 0 0 2px black;
  -webkit-animation: bar-fade-in 1.8s linear;
          animation: bar-fade-in 1.8s linear;
}

.ProgressMeter {
  margin-bottom: 10px;
}

.progressBar__measurement {
  height: 100%;
  border-radius: 0 5px 5px 0;
  border: 1px solid rgba(0,0,0,0.5);
  background-color: teal;
  box-shadow: 0 0 2px #fff, 0 0 4px #fff, 0 0 2px #fff inset, 0 0 4px #fff inset, 0 0 6px chartreuse, 0 0 10px chartreuse, 0 0 12px chartreuse, 0 0 15px chartreuse, 0 0 20px chartreuse;
  background: -webkit-linear-gradient(top, #009669 0%,#cff720 39%,#009669 100%);
  background: linear-gradient(to bottom, #009669 0%,#cff720 39%,#009669 100%);
  -webkit-transform-origin: 0% 50%;
          transform-origin: 0% 50%;
  -webkit-animation: bar-expand 1.5s ease-out;
          animation: bar-expand 1.5s ease-out;
}

.progressBar__measurement--lead {
  box-shadow: 0 0 3px rgb(139, 157, 167) inset, 0 0 20px rgb(139, 157, 167);
  background: -webkit-linear-gradient(top, #02110d  0%,#616363 39%,#02110d  100%);
  background: linear-gradient(to bottom, #02110d  0%,#616363 39%,#02110d  100%);
}

.progressBar__measurement--bronze {
  box-shadow: 0 0 2px #fff, 0 0 4px #fff, 0 0 2px #fff inset, 0 0 4px #fff inset, 0 0 6px #e4bc84, 0 0 10px #e4bc84, 0 0 12px #e4bc84, 0 0 15px #e4bc84, 0 0 20px #e4bc84;
  background: -webkit-linear-gradient(top, #66460c 0%,#e0b982 39%,#66460c 100%);
  background: linear-gradient(to bottom, #66460c 0%,#e0b982 39%,#66460c 100%);
}

.progressBar__measurement--silver {
  box-shadow: 0 0 2px #fff, 0 0 4px #fff, 0 0 2px #fff inset, 0 0 4px #fff inset, 0 0 6px #f5fcfc, 0 0 10px #f5fcfc, 0 0 12px #f5fcfc, 0 0 15px #f5fcfc, 0 0 20px #f5fcfc;
  background: -webkit-linear-gradient(top, #898a8a 0%,#e9ebeb 39%,#898a8a 100%);
  background: linear-gradient(to bottom, #898a8a 0%,#e9ebeb 39%,#898a8a 100%);
}

.progressBar__measurement--gold {
  box-shadow: 0 0 2px #fff, 0 0 4px #fff, 0 0 2px #fff inset, 0 0 4px #fff inset, 0 0 6px #fde596, 0 0 10px #fde596, 0 0 12px #fde596, 0 0 15px #fde596, 0 0 20px #fde596;
  background: -webkit-linear-gradient(top, #ba9e00  0%,#f8f9a7 39%,#ba9e00  100%);
  background: linear-gradient(to bottom, #ba9e00  0%,#f8f9a7 39%,#ba9e00  100%);
}

@-webkit-keyframes bar-expand {
  from {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
  }
  to {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
}

@keyframes bar-expand {
  from {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
  }
  to {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
}

@-webkit-keyframes bar-fade-in {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bar-fade-in {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (min-width: 750px) {
  .progressBar__heading {
    font-size: 1.2rem;
    text-shadow: 1px 2px 2px rgba(0,0,0,.5);
  }
}
.BottomButton {
  position: fixed;
  bottom: 10px;
  left: 50%;
  width: 200px;
  margin-left: -100px;
  color: white;
  font-size: 1.2rem;
  background-color: transparent;
  border: 0;
  text-shadow: 1px 2px 0 rgba(0,0,0,.5);
}

@media only screen and (min-width: 750px) {
  .BottomButton {
    border: 1px solid white;
    border-radius: 5px;
    padding: 5px 15px;
    -webkit-transition: all .3s;
    transition: all .3s;
    box-shadow: 0 0 2px rgba(0, 0, 0, .5), 0 0 4px rgba(0, 0, 0, .3) inset;
    cursor: pointer;
  }
  .BottomButton:hover {
    box-shadow: 2px 4px 4px rgba(0, 0, 0, .5), 2px 3px 4px rgba(0, 0, 0, .3) inset;
    text-shadow: 2px 4px 4px rgba(0,0,0,.5);
  }
}
.TaskDetail {
  -webkit-animation: fade-in .3s linear;
          animation: fade-in .3s linear;
  position: relative;
  padding-bottom: 3rem;
}

.taskDetail__segmented-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.taskDetail__segmented {
  color: white;
  margin: 0;
}

.segmented-control {
  border-radius: 8px !important;
  max-width: 450px !important;
}

.segmented-control label::after {
  color: black !important;
}

.taskDetail__sub-heading {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1rem;
  font-weight: normal;
  margin: 0;
}

.taskDetail__content {
  margin-top: 0;
  background-color: rgba(0, 0, 0, .3);
  padding: 5px 10px;
  border-radius: 8px;
}

.taskDetail__description {
  margin-top: 0;
}

.taskDetail__title {
  margin-top: 0;
  text-align: center;
  font-weight: bold;
}

@media only screen and (min-width: 750px) {
  .taskDetail__sub-heading {
    text-align: center;
    font-size: 1.2rem;
  }
  .taskDetail__title {
    font-size: 1.5rem;
  }
  .taskDetail__content {
    padding: 20px 30px;
    border-radius: 10px;
  }

  .segmented-control label {
    text-shadow: 2px 4px 4px rgba(0,0,0,.5);
  }
}
.BackButton {
  position: absolute;
  top: 5px;
  left: 0;
  padding: 0;
  font-size: 1.5rem;
  color: white;
  text-shadow: 2px 4px 0 rgba(0,0,0,.5);
  background: transparent;
  border: none;
}

.BackButton a {
  text-decoration: none;
  color: white;
}

@media only screen and (min-width: 750px) {
  .BackButton {
    cursor: pointer;
    -webkit-transition: text-shadow .3s;
    transition: text-shadow .3s;
  }
  .BackButton:hover {
    text-shadow: 3px 5px 4px rgba(0,0,0,.5);
  }
  .BackButton:active {
    -webkit-transition: all .2s;
    transition: all .2s;
    -webkit-transform: translate(1px, 1px);
            transform: translate(1px, 1px);
    text-shadow: 2px 4px 3px rgba(0,0,0,.5);
  }
}
.CompleteTask {
  -webkit-animation: fade-in .3s linear;
          animation: fade-in .3s linear;
  position: relative;
  padding-bottom: 3rem;
}
.ChallengeDetail {
  -webkit-animation: fade-in .3s linear;
          animation: fade-in .3s linear;
  position: relative;
  padding-top: 5px;
  padding-bottom: 3rem;
}

.challengeDetail__video {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  margin-bottom: 10px;
}

.challengeDetail__video iframe {
  box-shadow: 0 0 40px rgba(0, 0, 0, .5);
}

.challengeDetail__content {
  margin-top: 0;
  background-color: rgba(0, 0, 0, .3);
  padding: 5px 10px;
  border-radius: 8px;
}

.challengeDetail__bonus-heading {
  text-shadow: 1px 2px 0 rgba(0,0,0,.5);
  margin-bottom: .3em;
}

.challengeDetail__bonus-row {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.2em;
}

@media only screen and (min-width: 750px) {
  .challengeDetail__content {
    border-radius: 10px;
    padding: 20px 30px;
  }
  .challengeDetail__bonus-heading {
    font-size: 1.5rem;
  }
}
.Profile {
  -webkit-animation: fade-in .3s linear;
          animation: fade-in .3s linear;
}

.profile__sub-heading {
  text-shadow: 1px 2px 0 rgba(0, 0, 0, .5);
  font-size: 1.2rem;
}

.profile__content {
  margin-top: 0;
  background-color: rgba(0, 0, 0, .3);
  padding: 5px 10px;
  border-radius: 8px;
}

.profile__inline-link {
  color: white;
}

@media only screen and (min-width: 750px) {
  .profile__sub-heading {
    font-size: 1.3rem;
    text-shadow: 2px 3px 2px rgba(0, 0, 0, .5);
  }
  .profile__inline-link {
    -webkit-transition: all .3s;
    transition: all .3s;
    text-shadow: none;
    text-decoration: none;
    border-bottom: 1px solid rgba(255, 255, 255, .5);
  }
  .profile__inline-link:hover {
    text-shadow: 1px 2px 2px rgba(0, 0, 0, .5);
    border-bottom: 1px solid white;
  }
}
.EditProfile {
  position: relative;
  -webkit-animation: fade-in .3s linear;
          animation: fade-in .3s linear;
}

.editProfile__form {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-align-items: center;
          align-items: center;
  max-width: 600px;
  margin: 0 auto;
}

.editProfile__form-row {
  width: 100%;
  font-size: 1rem;
  margin: 5px 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.editProfile__form-row input {
  font-size: 1rem;
  width: 60%;
  padding: 3px;
}

.editProfile__form-row input::-webkit-input-placeholder {
  color: red;
}

.editProfile__form-row input::-ms-input-placeholder {
  color: red;
}

.editProfile__form-row input::placeholder {
  color: red;
}

.editProfile__buttons {
  max-width: 600px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  margin: .8rem auto .5rem;
}

.editProfile__submit, .editProfile__cancel, .editProfile__delete {
  font-size: 1rem;
  border: 0;
  background: transparent;
  cursor: pointer;
  margin: 0 10px;
}

.editProfile__submit, .editProfile__cancel {
  color: white;
}

.editProfile__delete {
  color: red;
}

.editProfile__hr {
  max-width: 600px;
  text-align: center;   
}

@media only screen and (min-width: 750px) {
  .editProfile__submit, .editProfile__cancel, .editProfile__delete {
    border: 1px solid white;
    border-radius: 5px;
    padding: 5px 15px;
    -webkit-transition: all .3s;
    transition: all .3s;
    box-shadow: 0 0 2px rgba(0, 0, 0, .5), 0 0 4px rgba(0, 0, 0, .3) inset;
    cursor: pointer;
    text-shadow: 1px 2px 2px rgba(0, 0, 0, .5);
  }
  .editProfile__submit:hover, .editProfile__cancel:hover, .editProfile__delete:hover {
    box-shadow: 2px 4px 4px rgba(0, 0, 0, .5), 2px 3px 4px rgba(0, 0, 0, .3) inset;
    text-shadow: 2px 4px 4px rgba(0,0,0,.5);
  }

  .editProfile__delete {
    border-color: red;
  }
}
.Authorization-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: -webkit-flex;
  display: flex;
  overflow: scroll;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  z-index: 20;
  background-color: rgba(157, 4, 4, 1);
  -webkit-animation: modal-enter .5s ease;
          animation: modal-enter .5s ease;
}

.Authorization-wrapper--leaving {
  padding-top: 100vh;
  background-color: rgba(157, 4, 4, 0);
  -webkit-animation: modal-exit .5s ease;
          animation: modal-exit .5s ease;
}

.Authorization {
  position: relative;
  width: 100%;
  padding: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-align-items: center;
          align-items: center;
}

.authorization__dismiss {
  text-align: right;
  cursor: pointer;
}

.authorization__heading {
  margin-top: 0;
  text-shadow: 2px 4px 0 rgba(0,0,0,.5);
  text-align: center;
}

.authorization__sub-heading {
  text-shadow: 2px 4px 0 rgba(0,0,0,.5);
}

.Login, .Register {
  width: 100%;
  max-width: 500px;
  -webkit-animation: fade-in .5s linear;
          animation: fade-in .5s linear;
}

.authorization__form {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-align-items: center;
          align-items: center;
}

.authorization__form-row {
  width: 100%;
  font-size: 1rem;
  margin: 5px 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.authorization__form-row input {
  font-size: 1rem;
  width: 60%;
  padding: 3px;
}

.authorization__form-row input::-webkit-input-placeholder {
  color: red;
}

.authorization__form-row input::-ms-input-placeholder {
  color: red;
}

.authorization__form-row input::placeholder {
  color: red;
}

.authorization__switch {
  text-align: center;
}

.authorization__switch-btns {
  margin: 10px auto;
  -webkit-transition: all .3s;
  transition: all .3s;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  max-width: 300px;
}

.authorization__switch-btn, .authorization__submit {
  margin-top: 10px;
  font-size: 1rem;
  color: white;
  border: 0;
  background: transparent;
  cursor: pointer;
}
.UserMessages {
  position: fixed;
  top: 0;
  left: 50%;
  margin-left: -150px;
  width: 300px;
  background: transparent;
  z-index: 25;
}

.MessageRow {
  padding: .3em .8em;
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  border-radius: 0 0 8px 8px;
  box-shadow: 1px 2px 0 rgba(0,0,0,.5);
  font-size: .9rem;
  height: 3em;
  overflow: hidden;
  -webkit-transition: all .2s linear;
  transition: all .2s linear;
  -webkit-animation: open-down .2s linear;
          animation: open-down .2s linear;
}

.messageRow--message {
  background-color: green;
  background: -webkit-linear-gradient(36deg, green, rgb(0, 70, 0));
  background: linear-gradient(0.15turn, green, rgb(0, 70, 0));
}

.messageRow--error {
  background-color: red; 
  background: -webkit-linear-gradient(36deg, rgb(255, 0, 0), rgb(170, 40, 1)); 
  background: linear-gradient(0.15turn, rgb(255, 0, 0), rgb(170, 40, 1));
}

.messageRow__dismiss {
  margin-left: 10px;
  padding-left: 10px;
  text-align: right;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-shadow: 1px 2px 0 rgba(0,0,0,.5);
  border-left: 1px solid rgba(255, 255, 255, .4);
}

.messageRow--dismissing {
  height: 0;
  padding: 0;
}

@-webkit-keyframes open-down {
  from {
    height: 0;
  }
  to {
    height: 3em;
  }
}

@keyframes open-down {
  from {
    height: 0;
  }
  to {
    height: 3em;
  }
}

@media only screen and (min-width: 750px) {
  .messageRow__dismiss {
    -webkit-transition: all .3s;
    transition: all .3s;
    cursor: pointer;
  }
  .messageRow__dismiss:hover {
    text-shadow: 2px 4px 3px rgba(0,0,0,.5);
  }
}
.AnnouncementsContainer {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 70px 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  z-index: 18;
}
.Announcement {
  overflow: scroll;
  width: 100%;
  max-width: 700px;
  height: 100%;
  overflow: scroll;
  background-color: beige;
  color: black;
  padding: 15px;
  box-shadow: 0 0 60px rgba(0, 0, 0, .5);
  position: relative;
}

.Announcement-holder {
  position: relative;
  -webkit-animation: slide-in .5s ease-out;
          animation: slide-in .5s ease-out;
  max-width: 700px;
  height: 100%;
}

.announcement--leaving {
  -webkit-transform: translateY(-100vh);
          transform: translateY(-100vh);
  -webkit-animation: slide-out .5s ease-in;
          animation: slide-out .5s ease-in;
}

.announcement__heading {
  font-size: 1.4rem;
  text-shadow: 2px 4px 4px rgba(0, 0, 0, .3);

}

.announcement__dismiss {
  position: absolute;
  color: black;
  top: 5px;
  right: 10px;
  width: 1em;
  text-align: right;
  font-size: 1.5rem;
  text-shadow: 2px 4px 4px rgba(0, 0, 0, .3);
  -webkit-animation: pulse 1s linear alternate infinite;
          animation: pulse 1s linear alternate infinite;
}

@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale(1,1);
            transform: scale(1,1);
  }
  to {
    -webkit-transform: scale(1.2,1.2);
            transform: scale(1.2,1.2);
  }
}

@keyframes pulse {
  from {
    -webkit-transform: scale(1,1);
            transform: scale(1,1);
  }
  to {
    -webkit-transform: scale(1.2,1.2);
            transform: scale(1.2,1.2);
  }
}

@-webkit-keyframes slide-in {
  0% {
    -webkit-transform: translateY(100vh);
            transform: translateY(100vh);
  }
  50% {
    -webkit-transform: translateY(25vh);
            transform: translateY(25vh);
  }
  75% {
    -webkit-transform: translateY(8vh);
            transform: translateY(8vh);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes slide-in {
  0% {
    -webkit-transform: translateY(100vh);
            transform: translateY(100vh);
  }
  50% {
    -webkit-transform: translateY(25vh);
            transform: translateY(25vh);
  }
  75% {
    -webkit-transform: translateY(8vh);
            transform: translateY(8vh);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@-webkit-keyframes slide-out {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
  }
  100% {
    -webkit-transform: translateY(-100vh);
            transform: translateY(-100vh);
  }
}

@keyframes slide-out {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
  }
  100% {
    -webkit-transform: translateY(-100vh);
            transform: translateY(-100vh);
  }
}

@media only screen and (min-width: 750px) {
  .announcement__dismiss {
    cursor: pointer;
    -webkit-transition: all .3s;
    transition: all .3s;
  }
  .announcement__dismiss:hover {
    color: rgba(157, 4, 4, 1);
    -webkit-transform: scale(1.1,1.1);
            transform: scale(1.1,1.1);
  }
}
