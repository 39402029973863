.Authorization-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  overflow: scroll;
  justify-content: center;
  align-items: flex-start;
  z-index: 20;
  background-color: rgba(157, 4, 4, 1);
  animation: modal-enter .5s ease;
}

.Authorization-wrapper--leaving {
  padding-top: 100vh;
  background-color: rgba(157, 4, 4, 0);
  animation: modal-exit .5s ease;
}

.Authorization {
  position: relative;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.authorization__dismiss {
  text-align: right;
  cursor: pointer;
}

.authorization__heading {
  margin-top: 0;
  text-shadow: 2px 4px 0 rgba(0,0,0,.5);
  text-align: center;
}

.authorization__sub-heading {
  text-shadow: 2px 4px 0 rgba(0,0,0,.5);
}

.Login, .Register {
  width: 100%;
  max-width: 500px;
  animation: fade-in .5s linear;
}

.authorization__form {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.authorization__form-row {
  width: 100%;
  font-size: 1rem;
  margin: 5px 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}

.authorization__form-row input {
  font-size: 1rem;
  width: 60%;
  padding: 3px;
}

.authorization__form-row input::placeholder {
  color: red;
}

.authorization__switch {
  text-align: center;
}

.authorization__switch-btns {
  margin: 10px auto;
  transition: all .3s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 300px;
}

.authorization__switch-btn, .authorization__submit {
  margin-top: 10px;
  font-size: 1rem;
  color: white;
  border: 0;
  background: transparent;
  cursor: pointer;
}