.MenuButton {
  height: 24px;
  width: 30px;
  position: relative;
  padding: 0;
}

.mb_line {
  position: absolute;
  height: 4px;
  width: 30px;
  background-color: white;
  transition: all .3s ease-out;
  border-radius: 2px;
  box-shadow: 1px 2px 4px rgba(0,0,0,.4);
}

.mb_line_1 {
  top: 0;
  left: 0;
}

.mb_line_2 {
  top: 10px;
  right: 0;
}

.mb_line_3 {
  top: 20px;
}

.mb_open .mb_line_1 {
  left: 4px;
  transform-origin: 0 50%;
  transform: rotate(45deg);
}

.mb_open .mb_line_3 {
  top: 0;
  right: 5px;
  transform-origin: 100% 50%;
  transform: rotate(-45deg);
}

.mb_open .mb_line_2 {
  top: 26px;
}