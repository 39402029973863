.Profile {
  animation: fade-in .3s linear;
}

.profile__sub-heading {
  text-shadow: 1px 2px 0 rgba(0, 0, 0, .5);
  font-size: 1.2rem;
}

.profile__content {
  margin-top: 0;
  background-color: rgba(0, 0, 0, .3);
  padding: 5px 10px;
  border-radius: 8px;
}

.profile__inline-link {
  color: white;
}

@media only screen and (min-width: 750px) {
  .profile__sub-heading {
    font-size: 1.3rem;
    text-shadow: 2px 3px 2px rgba(0, 0, 0, .5);
  }
  .profile__inline-link {
    transition: all .3s;
    text-shadow: none;
    text-decoration: none;
    border-bottom: 1px solid rgba(255, 255, 255, .5);
  }
  .profile__inline-link:hover {
    text-shadow: 1px 2px 2px rgba(0, 0, 0, .5);
    border-bottom: 1px solid white;
  }
}