.ChallengesList {
  width: 100%;
  margin-bottom: 15px;
}
.challengesList__wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  animation: fade-in .3s linear;
}

@media only screen and (min-width: 750px) {
  .challengesList__wrapper {
    justify-content: center;
  }
}