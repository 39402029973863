.BottomButton {
  position: fixed;
  bottom: 10px;
  left: 50%;
  width: 200px;
  margin-left: -100px;
  color: white;
  font-size: 1.2rem;
  background-color: transparent;
  border: 0;
  text-shadow: 1px 2px 0 rgba(0,0,0,.5);
}

@media only screen and (min-width: 750px) {
  .BottomButton {
    border: 1px solid white;
    border-radius: 5px;
    padding: 5px 15px;
    transition: all .3s;
    box-shadow: 0 0 2px rgba(0, 0, 0, .5), 0 0 4px rgba(0, 0, 0, .3) inset;
    cursor: pointer;
  }
  .BottomButton:hover {
    box-shadow: 2px 4px 4px rgba(0, 0, 0, .5), 2px 3px 4px rgba(0, 0, 0, .3) inset;
    text-shadow: 2px 4px 4px rgba(0,0,0,.5);
  }
}