.ChallengeDetail {
  animation: fade-in .3s linear;
  position: relative;
  padding-top: 5px;
  padding-bottom: 3rem;
}

.challengeDetail__video {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.challengeDetail__video iframe {
  box-shadow: 0 0 40px rgba(0, 0, 0, .5);
}

.challengeDetail__content {
  margin-top: 0;
  background-color: rgba(0, 0, 0, .3);
  padding: 5px 10px;
  border-radius: 8px;
}

.challengeDetail__bonus-heading {
  text-shadow: 1px 2px 0 rgba(0,0,0,.5);
  margin-bottom: .3em;
}

.challengeDetail__bonus-row {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.2em;
}

@media only screen and (min-width: 750px) {
  .challengeDetail__content {
    border-radius: 10px;
    padding: 20px 30px;
  }
  .challengeDetail__bonus-heading {
    font-size: 1.5rem;
  }
}