.EditProfile {
  position: relative;
  animation: fade-in .3s linear;
}

.editProfile__form {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
}

.editProfile__form-row {
  width: 100%;
  font-size: 1rem;
  margin: 5px 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}

.editProfile__form-row input {
  font-size: 1rem;
  width: 60%;
  padding: 3px;
}

.editProfile__form-row input::placeholder {
  color: red;
}

.editProfile__buttons {
  max-width: 600px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  margin: .8rem auto .5rem;
}

.editProfile__submit, .editProfile__cancel, .editProfile__delete {
  font-size: 1rem;
  border: 0;
  background: transparent;
  cursor: pointer;
  margin: 0 10px;
}

.editProfile__submit, .editProfile__cancel {
  color: white;
}

.editProfile__delete {
  color: red;
}

.editProfile__hr {
  max-width: 600px;
  text-align: center;   
}

@media only screen and (min-width: 750px) {
  .editProfile__submit, .editProfile__cancel, .editProfile__delete {
    border: 1px solid white;
    border-radius: 5px;
    padding: 5px 15px;
    transition: all .3s;
    box-shadow: 0 0 2px rgba(0, 0, 0, .5), 0 0 4px rgba(0, 0, 0, .3) inset;
    cursor: pointer;
    text-shadow: 1px 2px 2px rgba(0, 0, 0, .5);
  }
  .editProfile__submit:hover, .editProfile__cancel:hover, .editProfile__delete:hover {
    box-shadow: 2px 4px 4px rgba(0, 0, 0, .5), 2px 3px 4px rgba(0, 0, 0, .3) inset;
    text-shadow: 2px 4px 4px rgba(0,0,0,.5);
  }

  .editProfile__delete {
    border-color: red;
  }
}