.ProgressBar {
  margin-bottom: 10px;
}

.progressBar__heading {
  margin: 0 auto 5px;
  width: 90%;
  max-width: 500px;
  text-shadow: 1px 2px 0 rgba(0,0,0,.5);
}

.ProgressMeter {
  margin-bottom: 10px;
}

.progressBar__holder {
  width: 90%;
  max-width: 500px;
  height: 30px;
  border: 3px ridge dimgray;
  border-radius: 8px;
  overflow: hidden;
  background-color: rgb(50,50,50);
  position: relative;
  box-shadow: 2px 2px 6px rgba(0,0,0,0.5);
  margin: 0 auto;
}

.ProgressMeter {
  margin-bottom: 10px;
}

.progressBar__meter-title {
  font-size: 1rem;
  padding: 0 8px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  font-weight: bold;
  text-shadow: 0 0 2px black;
  animation: bar-fade-in 1.8s linear;
}

.ProgressMeter {
  margin-bottom: 10px;
}

.progressBar__measurement {
  height: 100%;
  border-radius: 0 5px 5px 0;
  border: 1px solid rgba(0,0,0,0.5);
  background-color: teal;
  box-shadow: 0 0 2px #fff, 0 0 4px #fff, 0 0 2px #fff inset, 0 0 4px #fff inset, 0 0 6px chartreuse, 0 0 10px chartreuse, 0 0 12px chartreuse, 0 0 15px chartreuse, 0 0 20px chartreuse;
  background: linear-gradient(to bottom, #009669 0%,#cff720 39%,#009669 100%);
  transform-origin: 0% 50%;
  animation: bar-expand 1.5s ease-out;
}

.progressBar__measurement--lead {
  box-shadow: 0 0 3px rgb(139, 157, 167) inset, 0 0 20px rgb(139, 157, 167);
  background: linear-gradient(to bottom, #02110d  0%,#616363 39%,#02110d  100%);
}

.progressBar__measurement--bronze {
  box-shadow: 0 0 2px #fff, 0 0 4px #fff, 0 0 2px #fff inset, 0 0 4px #fff inset, 0 0 6px #e4bc84, 0 0 10px #e4bc84, 0 0 12px #e4bc84, 0 0 15px #e4bc84, 0 0 20px #e4bc84;
  background: linear-gradient(to bottom, #66460c 0%,#e0b982 39%,#66460c 100%);
}

.progressBar__measurement--silver {
  box-shadow: 0 0 2px #fff, 0 0 4px #fff, 0 0 2px #fff inset, 0 0 4px #fff inset, 0 0 6px #f5fcfc, 0 0 10px #f5fcfc, 0 0 12px #f5fcfc, 0 0 15px #f5fcfc, 0 0 20px #f5fcfc;
  background: linear-gradient(to bottom, #898a8a 0%,#e9ebeb 39%,#898a8a 100%);
}

.progressBar__measurement--gold {
  box-shadow: 0 0 2px #fff, 0 0 4px #fff, 0 0 2px #fff inset, 0 0 4px #fff inset, 0 0 6px #fde596, 0 0 10px #fde596, 0 0 12px #fde596, 0 0 15px #fde596, 0 0 20px #fde596;
  background: linear-gradient(to bottom, #ba9e00  0%,#f8f9a7 39%,#ba9e00  100%);
}

@keyframes bar-expand {
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
}

@keyframes bar-fade-in {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (min-width: 750px) {
  .progressBar__heading {
    font-size: 1.2rem;
    text-shadow: 1px 2px 2px rgba(0,0,0,.5);
  }
}