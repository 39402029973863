.Header-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 15;
  background-color: rgba(157, 4, 4, 1);
  backdrop-filter: blur(10px);
  box-shadow: 0 2px 4px 2px rgba(0,0,0,.5);
}

.Header {
  margin: 0 auto;
  width: 100%;
  max-width: 800px;
  padding: .5rem;
}

.header__top-line {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.header__logo {
  margin: 0;
  font-size: 1.5rem;
  letter-spacing: .1em;
  text-shadow: 2px 4px 2px rgba(0,0,0,.5);
}

.header__nav {
  height: 0;
  overflow: hidden;
  transition: all .3s ease;
  text-align: center;
}

.header__nav--visible {
  height: 11.5rem;
}

.header__nav-list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-flow: column nowrap;
  text-align: center;
  margin: .5rem 0 0;
}

.header__nav-item {
  display: inline-block;
  padding: .8rem;
  line-height: 1em;
  font-size: 1.2rem;
  border-bottom: 1px solid rgba(255, 255, 255, .4);
}

.header__nav-item a {
  color: white;
  text-decoration: none;
}

@media only screen and (min-width: 750px) {
  .Header-wrapper {
    box-shadow: 0 2px 10px 4px rgba(0,0,0,.5);
  }
  .Header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-end;
    padding: 1.5rem 10px;
    max-width: 900px;
  }

  .header__logo {
    font-size: 2rem;
  }
  
  .MenuButton {
    display: none;
  }
  .header__nav {
    height: auto;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
    text-align: right;
  }
  .header__nav-list {
    flex-flow: row nowrap;
    margin: 0;
  }
  .header__nav-item {
    border-bottom: 2px solid transparent;
    padding: .4em 0;
    margin: 0 0 0 1.2em;
    transition: all .3s;
    cursor: pointer;
  }
  .header__nav-item, .header__nav-item a {
    font-family: 'Permanent Marker', cursive;
    text-shadow: 2px 4px 2px rgba(0, 0, 0, .5);
  }
  .header__nav-item:hover {
    border-bottom: 2px solid rgba(255,255,255, .5);
  }
}