.DailyTask {
  margin: 10px;
  text-align: center;
  width: 6rem;
  height: 6rem;
  border-radius: 8px;
  box-shadow: 0 0 50px rgba(0, 0, 0, .2);
  backdrop-filter: blur(50px);
  padding: 5px;
  animation: fade-in .3s linear;
}

.dailyTask__title {
  margin: 0;
  font-size: 1rem;
  line-height: 1em;
  font-family: 'Source Sans Pro', sans-serif;
  text-shadow: 1px 2px 0 rgba(0,0,0,.5);
}

.dailyTask__complete {
  font-size: 3rem;
  margin: 0;
}

.dailyTask__complete a {
  text-decoration: none;
}

@media only screen and (min-width: 750px) {
  .DailyTask {
    width: 7rem;
    height: 7rem;
    transition: all .3s;
  }
  .DailyTask:hover {
    transform: scale(1.05, 1.05);
    box-shadow: 0 0 50px 4px rgba(0, 0, 0, .2);
  }
  .dailyTask__complete {
    transition: all .3s;
  }
  .dailyTask__complete:hover {
    transform: scale(1.1, 1.1);
  }
  .dailyTask__title {
    font-size: 1.2rem;
  }
}